



















import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/admin/components/PageHeader.vue'
import __ from '@/shared/helpers/__'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import Tool from '@/shared/modules/tool/models/tool.model'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import { CompanyRoutes } from '@/shared/router/company'
import Can from '@/shared/components/Can.vue'
import permissions from '@/shared/helpers/permissions.helper'
import EditAction from '@/shared/classes/components/data-table/default-actions/edit-action'
import DataTableFilter, { FilterOperators } from '@/shared/classes/components/data-table/data-table-filter'
import { FieldSizes } from '@/shared/classes/form/field'
import SearchableField from '@/shared/classes/form/fields/Select/searchable-field'
import DataTableHeaderTypes from '@/shared/classes/components/data-table/data-table-header-types'
import DataTableAction from '@/shared/classes/components/data-table/data-table-action'
import User from '@/shared/modules/user/user.model'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import router from '@/shared/router'
import can from '@/shared/helpers/can.helper'
import ShowAction from '@/shared/classes/components/data-table/default-actions/show-action'
import ToolService, { GenerateType } from '@/shared/modules/tool/tool.service'
import { downloadFile } from '@/shared/helpers/download-file.helper'


@Component({
    components: { Can, DataTable, PageHeader },
    methods: { __ }
  })
  export default class ToolsIndex extends Vue {
    types = GenerateType
    permissions = permissions
    table: DataTableBase|null = null
    loading: GenerateType|null = null

    created() {
      this.table = new DataTableBase()
        .setModel(Tool)
        .setEndpoint(`company/${ this.company.slug }/tools`)
        .setDoubleClick(this.editTool)
        .setMultipleCheckbox(true)
        .setRowFilterInHeading(true)
        .setHeaders([
          new DataTableHeader()
            .setType(DataTableHeaderTypes.textLink)
            .setKey('internal_no')
            .setText(__('company.views.tools.index.table.columns.internal_no'))
            .setMeta({
              getRawLocation: (tool: Tool) => ({
                path: `${ this.$router.currentRoute.path }/${ tool.uuid }`
              })
            }),
          new DataTableHeader()
            .setKey('name')
            .setEntryKey('name')
            .setSortable(false)
            .setText(__('company.views.tools.index.table.columns.name')),
          new DataTableHeader()
            .setKey('location.name')
            .setSortable(false)
            .setText(__('company.views.tools.index.table.columns.location')),
          new DataTableHeader()
            .setKey('category.name')
            .setSortable(false)
            .setText(__('company.views.tools.index.table.columns.category')),
          new DataTableHeader()
            .setKey('rental_external_price')
            .setEntryKey('rental_external_price')
            .setSortable(false)
            .setText(__('company.views.tools.index.table.columns.rental_external_price')),
          new DataTableHeader()
            .setKey('rental_internal_price')
            .setEntryKey('rental_internal_price')
            .setSortable(false)
            .setText(__('company.views.tools.index.table.columns.rental_internal_price')),
          new DataTableHeader()
            .setKey('retrieved_by.full_name')
            .setEntryKey('retrievedBy.fullName')
            .setSortable(false)
            .setText(__('company.views.tools.index.table.columns.retrieved-by')),
          new DataTableHeader()
            .setKey('comment')
            .setEntryKey('comment')
            .setSortable(false)
            .setText(__('company.views.tools.index.table.columns.comment')),
          new DataTableHeader()
            .setType(DataTableHeaderTypes.imageWithText)
            .setKey('attachments_count')
            .setEntryKey('attachmentsCount')
            .setText(__('company.views.tools.index.table.columns.thumbnail'))
            .setClasses('d-flex justify-start align-center')
            .setMeta({
              icon: 'mdi-attachment mdi-rotate-90',
              width: 50,
              height: 30,
              imgKey: 'lastAttachment',
            })
            .setSortable(false),
          new DataTableHeader()
            .setType(DataTableHeaderTypes.textLink)
            .setKey('link')
            .setText(__('company.views.tools.index.table.columns.link'))
            .setSortable(false)
            .setMeta({
              getRawLocation: (tool: Tool) => ({
                path: tool.link
              })
            }),
        ])
        .setFilters([
          new DataTableFilter()
            .setOperator(FilterOperators.equals)
            .setKeyIsPrimary()
            .setField(
              new SearchableField()
                .setKey('location_uuid')
                .setTitle(__('company.views.tools.index.table.filters.location'))
                .loadItems({
                  endpoint: `/company/${ this.company.slug }/tool-locations`,
                  additionalEndpoint: 'autocomplete',
                  value: 'uuid',
                  title: 'name',
                  perPage: 20
                })
                .setSize(FieldSizes.threeHalfTwelfth)
            ),
          new DataTableFilter()
            .setOperator(FilterOperators.equals)
            .setKeyIsPrimary()
            .setField(
              new SearchableField()
                .setKey('category_uuid')
                .setTitle(__('company.views.tools.index.table.filters.category'))
                .loadItems({ endpoint: `/company/${ this.company.slug }/tool-categories`, value: 'uuid', title: 'name', perPage: 20 })
                .setSize(FieldSizes.threeHalfTwelfth)
            ),
          new DataTableFilter()
            .setOperator(FilterOperators.equals)
            .setKeyIsPrimary()
            .setField(
              new SearchableField()
                .setKey('retrieved_by_user_uuid')
                .setTitle(__('company.views.tools.index.table.filters.employee'))
                .loadItems({
                  endpoint: `/company/${ this.company.slug }/employees`,
                  additionalEndpoint: 'autocomplete',
                  value: 'uuid',
                  title: 'full_name',
                  perPage: 20
                })
                .setSize(FieldSizes.threeHalfTwelfth)
            )
        ])
        .setDefaultFilters([
          {
            operator: FilterOperators.nulled,
            key: 'discarded_at',
            value: true
          }
        ])
        .setActions([
          new ShowAction(),
          new EditAction()
            .setPermissions([
              permissions.company.tools.show,
              permissions.company.tools.update,
            ]),
          new DataTableAction()
            .setPermissions([
                permissions.company.tools.move,
              ])
              .setIcon('mdi-cursor-move')
              .setTitle(__('company.views.tools.move.title'))
              .setAction(async (item: Tool) => {
                this.$router.push({ name: CompanyRoutes.toolsMove, params: { tools: [item.uuid].join(',') } })
              }),
        ])
    }

    navigateToCreate(): void {
      this.$router.push({ name: CompanyRoutes.toolsCreate })
    }

    navigateToMove(): void {
      const uuids = this.table ? this.table.data.filter(i => i.selected).map(i => i.uuid) : []
      this.$router.push({ name: CompanyRoutes.toolsMove, params: { tools: [uuids].join(',') } })
    }

    editTool(item: Tool): void {
      if (can([permissions.company.tools.update], false, item)) {
        this.$router.push({ path: `${ router.currentRoute.path }/${ item.uuid }/edit`})
      } else if (can([permissions.company.tools.show])) {
        this.$router.push({ path: `${ router.currentRoute.path }/${ item.uuid }`})
      }
    }

    generate(type: GenerateType): void {
      this.loading = type
      const query = this.$router.currentRoute.query
      const location = (query['filter-equals-location_uuid'] ?? '') as string
      const category = (query['filter-equals-category_uuid'] ?? '') as string
      const q = (query['q'] ?? '') as string

      ToolService.export(this.company, type, location, category, q, false)
        .then((response: any) => {
          downloadFile(response, `tools.${ type }`)
        })
        .finally(() => this.loading = null)
    }

    get company(): Company {
      return this.$store.getters[CompanyGetters.getCompany]
    }

    get user(): User {
      return this.$store.getters[AuthGetters.getUser]
    }
  }
