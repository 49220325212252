import __ from '@/shared/helpers/__'
import router from '@/shared/router'
import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/modules/model-response.interface'
import DataTableAction from '@/shared/classes/components/data-table/data-table-action'

export default class ShowAction extends DataTableAction {
  constructor() {
    super()
    this.setIcon('mdi-eye')
    this.setTitle(__('components.table.actions.show'))
    this.setAction((item: Model<IModelResponse>) => router.push({ path: `${ router.currentRoute.path }/${ item.uuid }`}))
  }
}
